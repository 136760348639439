<template>
  <div class="box-content">
    <search :makeSearch="makeSearch">
      <template #buttons>
        <button
          @click="refreshTransactions"
          class="button is-outlined is-small"
        >
          Actualiser
        </button>
        <drop-down title="Effectuer une recherche avancée" ref="dateSearch">
          <template>
            <li>
              <label>Date début</label>
              <input v-model="model.date_debut" type="date" class="input" />
            </li>
            <li>
              <label>Date fin</label>
              <input v-model="model.date_fin" type="date" class="input" />
            </li>
            <li v-if="profile == 1 || profile == 2">
              <div>
                <label>Type d'opération</label>
                <v-select
                  :options="operations"
                  placeholder="Selectionner"
                  v-model="model.operation"
                  label="name"
                  :reduce="(item) => item.id"
                ></v-select>
              </div>
            </li>
            <li v-if="profile == 1">
              <div>
                <label>Client</label>
                <v-select
                  :options="customers"
                  placeholder="Selectionner"
                  v-model="model.sp"
                  label="name"
                  :reduce="(item) => item.name"
                ></v-select>
              </div>
            </li>
            <li>
              <div class="pt-10 b-flex">
                <button @click="getByDate" class="button is-outlined is-small">
                  Valider
                </button>
                <button @click="cancelDate" class="button is-outlined is-small">
                  Annuler
                </button>
              </div>
            </li>
          </template>
        </drop-down>
        <button
          @click="exportTransaction"
          :class="
            loading_export
              ? 'button is-outlined is-small is-loading'
              : 'button is-outlined is-small'
          "
        >
          Exporter
        </button>
      </template>
    </search>
    <data-table
      :columns="columns"
      :loading="loading"
      :counter="transactions.length"
      :meta="meta"
      :navigationFunction="navigationFunction"
    >
      <template #body>
        <tbody>
          <tr v-for="(item, key) in transactions" :key="key">
            <td>{{ item.operation_id }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.bank }}</td>
            <td v-if="profile == 1">{{ item.customer }}</td>
            <td>{{ item.service }}</td>
            <td>{{ item.operation_desciption }}</td>
            <td>
              <span v-if="item.result == '0'" class="tag is-success is-light">{{
                item.result_description
              }}</span>
              <span v-else class="tag is-primary is-light">{{
                item.result_description
              }}</span>
            </td>
            <td>{{ item.operation_date_time }}</td>
          </tr>
        </tbody>
      </template>
    </data-table>
  </div>
</template>

<script>
import dataTable from "@/components/table/datatable";
import DropDown from "@/components/DropDown";
import search from "@/components/table/search";
import { TYPES } from "@/store/types";
import { mapGetters } from "vuex";
import moment from "moment";
import { isMcp, mcpProfile } from "@/utils/Auth";
const m = mcpProfile();
export default {
  name: "transactions",
  components: {
    dataTable,
    search,
    DropDown,
  },
  data: () => ({
    loading: false,
    loading_export: false,
    profile: '',
    columns: [
      { name: "operation_id", label: "N° opération" },
      { name: "phone", label: "Téléphone" },
      { name: "total_amount", label: "Montant (XOF)" },
      { name: "bank", label: "Opérateur" },
      { name: "customer", label: "Client", state: m },
      { name: "service", label: "Service" },
      { name: "operation_desciption", label: "Opération" },
      { name: "result_description", label: "Statut" },
      { name: "operation_date_time", label: "Date opération" },
    ],
    operations: [
      //'DEBIT', 'CREDIT', 'TRANSFER', 'BALANCE', 'VERIFY', 'QUERY', 'EVALUATE', 'REFUND', 'IDENTITY'
      { name: "TOUS", id: 67 },
      { name: "DEBIT", id: 76 },
      { name: "CREDIT", id: 1 },
      { name: "TRANSFER", id: 2 },
      { name: "BALANCE", id: 3 },
      { name: "VERIFY", id: 4 },
      { name: "QUERY", id: 5 },
      { name: "EVALUATE", id: 6 },
      { name: "REFUND", id: 7 },
      { name: "IDENTITY", id: 8 },
    ],
    model: {
      page: 1,
      phone: "",
      date_debut: "",
      date_fin: "",
      sp: "",
      operation: 67,
    },
    is_mcp: null,
  }),

  computed: {
    ...mapGetters({
      transactions: `${TYPES.modules.TRANSACTIONS}/${TYPES.getters.GET_TRANSACTIONS}`,
      meta: `${TYPES.modules.TRANSACTIONS}/${TYPES.getters.GET_TRANSACTIONS_META}`,
      customers: `${TYPES.modules.UTILS}/${TYPES.getters.GET_MCP}`,
    }),
  },

  mounted() {
    this.profile = mcpProfile();
    this.getTransactions();
    if (this.profile == 1) this.getCustomers();
  },

  created() {
  },

  methods: {
    navigationFunction(page) {
      this.model.page = page;
      this.getTransactions();
    },

    getCustomers() {
      this.$store.dispatch(
        `${TYPES.modules.UTILS + "/" + TYPES.actions.GET_MCP}`
      );
    },

    exportTransaction() {
      this.loading_export = true;
      this.$store
        .dispatch(
          `${
            TYPES.modules.TRANSACTIONS + "/" + TYPES.actions.EXPORT_TRANSACTIONS
          }`,
          this.model
        )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = link.href = url;
          link.setAttribute("download", "transactions.xlsx");
          document.body.appendChild(link);
          link.click();
          this.loading_export = false;
        })
        .catch((error) => {
          this.loading_export = false;
        });
    },

    getByDate() {
      if (this.model.date_debut == "" || this.model.date_fin == "") {
        this.$toast.error("Veuillez saisir la date début et fin");
      } else {
        if (moment(this.model.date_fin).isBefore(this.model.date_debut)) {
          this.$toast.error(
            "La date début doit être inférieur à la date de fin"
          );
        } else {
          this.getTransactions();
          this.$refs.dateSearch.closeExit();
        }
      }
    },

    cancelDate() {
      this.model.page = 1;
      this.model.date_debut = "";
      this.model.date_fin = "";
      this.model.sp = "";
      this.model.operation = "";
      this.$refs.dateSearch.closeExit();
    },

    getTransactions() {
      this.loading = true;
      this.$store
        .dispatch(
          `${
            TYPES.modules.TRANSACTIONS + "/" + TYPES.actions.GET_TRANSACTIONS
          }`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    makeSearch(search) {
      this.model.phone = search;
      this.model.page = 1;
      this.loading = true;
      this.$store
        .dispatch(
          `${
            TYPES.modules.TRANSACTIONS + "/" + TYPES.actions.GET_TRANSACTIONS
          }`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    refreshTransactions() {
      this.loading = true;
      this.model.page = 1;
      this.model.phone = "";
      this.model.date_debut = "";
      this.model.date_fin = "";
      this.model.operation = 67;
      this.model.sp = "";
      this.$store
        .dispatch(
          `${
            TYPES.modules.TRANSACTIONS + "/" + TYPES.actions.GET_TRANSACTIONS
          }`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    showModal() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped>
.b-flex {
  display: flex;
  justify-content: space-between;
}
</style>